import moment from 'moment'

type FareType =
  | 'child'
  | 'adult'
  | 'reduced'
  | 'caregiver'
  | 'infant'
  | 'invalid'

type Ticket = {
  type: FareType
  name: string
  type_id: number
  fare: number
}

type Price = {
  total: number
  tickets: Ticket[]
}

export type AdditionalPassenger = {
  name: string
  telno: string
  type: number
  ride: boolean
  memo: string
}

// 過去履歴のためのランドマーク情報
export type ReffrenceLandmark = {
  id: number
  name: string
  type: string
  address: string
  lng: number
  lat: number
  area_id: number
  layover_landmark_type?: string
  layover_landmark_id?: string
  layover_landmark_name?: string
  layover_landmark_address?: string
  layover_landmark_location: {
    lat: number
    lng: number
  }
  layover_landmark_area_id: number
  available?: boolean
  date_stop_start?: string
  date_stop_end?: string
  stop_comment?: string
}

export type Order = {
  pickup_planned_location_lng: number
  booking_ip: string
  user_id: number
  pickup_execution_location: any
  status_transport: string
  status_reserved: boolean
  pickup_planned_location: any
  dropoff_planned_location_address: string
  reservation_timeout: any
  dropoff_planned_location_name: string
  pickup_planned_location_lat: number
  pickup_execution_location_lat: number
  pickup_planned_location_address: string
  capacity_requirements: number
  detour_factor: number
  current_status: OrderStatus
  price: Price
  status_pending: boolean
  status_confirmed: boolean
  pickup_execution_location_lng: number
  status_not_appeared: boolean
  pickup_planned_location_name: string
  dropoff_execution_location_lat: number
  booking_agent_id: number
  dropoff_execution_location_lng: number
  additional_passengers: AdditionalPassenger[]
  updated: Date
  created: Date
  id: number
  dropoff_planned_location: any
  status_canceled: boolean
  pickup_earliest: string
  mac_address: string
  pickup_time_window: number
  original_dropoff_latest: string
  dropoff_latest: string
  direct_travel_time: number
  dropoff_execution_location: any
  route_ids: number[]
  dropoff_planned_location_lat: number
  date_planned_type: string
  date_planned_start: string
  date_planned_end: string
  date_execution_start: string
  date_execution_end: string
  original_date_planned_type?: string
  original_date_planned_start?: string
  status_completed: boolean
  tag: string
  dropoff_planned_location_lng: number
  memo: string
  user_phone_number: string
  user_name: string
  ticket_number: string
  pickup_landmark_type: string
  pickup_landmark_id: number
  dropoff_landmark_type: string
  dropoff_landmark_id: number
  coupon_price: number
  layover_origin_order_id: number
  layover_destination_order_id: number
  reffrence_pickup_landmark: ReffrenceLandmark
  reffrence_dropoff_landmark: ReffrenceLandmark
  vehicle_id: number
  vehicle_name: string
  notified: boolean
}

type SimplePrice = {
  type: string
  type_id: number
  telno: string
  memo: string
  value: number
}

export type SimplePassenger = {
  name: string
  phone: string
  price: SimplePrice
}

export type SimpleOrder = {
  id: number
  user_id: number
  name: string
  from: {
    id: number
    landmark_type: string
    landmark_id: number
    name: string
    address: string
    location: { lat: number; lng: number }
    area_id: number
    layover_landmark_type?: string
    layover_landmark_id?: string
    layover_landmark_name?: string
    layover_landmark_address?: string
    layover_landmark_location: {
      lat: number
      lng: number
    }
    layover_landmark_area_id: number
    available?: boolean
    date_stop_start?: string
    date_stop_end?: string
    stop_comment?: string
  }
  date_planned_type: string
  fromRequestTime: string
  fromActualTime: string
  to: {
    id: number
    landmark_type: string
    landmark_id: number
    name: string
    address: string
    location: { lat: number; lng: number }
    area_id: number
    layover_landmark_type?: string
    layover_landmark_id?: string
    layover_landmark_name?: string
    layover_landmark_address?: string
    layover_landmark_location: {
      lat: number
      lng: number
    }
    layover_landmark_area_id: number
    available?: boolean
    date_stop_start?: string
    date_stop_end?: string
    stop_comment?: string
  }
  toRequestTime: string
  toActualTime: string
  passengers: SimplePassenger[]
  additional_passengers: AdditionalPassenger[]
  remarks: string
  coupon_price: number
  layover_origin_order_id: number
  layover_destination_order_id: number
}

export type OrderStatus =
  | 'RESERVED'
  | 'PENDING'
  | 'TRANSPORT'
  | 'CANCELED'
  | 'CONFIRMED'
  | 'COMPLETED'
  | 'NOT_APPEARED'

export const statusToString = (status: OrderStatus) => {
  const rawStatus = {
    RESERVED: '配車待ち',
    PENDING: '予約確認待ち',
    TRANSPORT: '移動中',
    CANCELED: 'キャンセル',
    CONFIRMED: '配車待ち',
    COMPLETED: '降車完了',
    NOT_APPEARED: 'スキップ'
  }
  return rawStatus[status]
}

export const getOrders = ($axios: any, the_date: string) => {
  return $axios.$get(`/orders/?date=${the_date}`).then((res: Order[]) => {
    res
      .filter((order: Order) => {
        return order.current_status === 'CONFIRMED'
      })
      .map((order: Order) => {
        return order
      })
    return res
  })
}

function zip<A, B>(array1: A[], array2: B[]): (A | B)[][] {
  return array1.map((_, c) => [array1, array2].map((row) => row[c]))
}

/*
export function getJapaneseFareType(fareType: FareType): string {
  if (fareType === 'child') {
    return '小学生'
  } else if (fareType === 'adult') {
    return '大人 (中学生以上)'
  } else if (fareType === 'reduced') {
    return '障害者手帳所持者'
  } else if (fareType === 'caregiver') {
    return '障害者手帳所持者の介護者'
  } else if (fareType === 'infant') {
    return '未就学児 (料金無料)'
  } else if (fareType === 'invalid') {
    return '大人 (中学生以上)'
  } else {
    return '不明'
  }
}
*/

export const toSimpleOrder = (order: Order): SimpleOrder => {
  const passengers = zip(order.price.tickets, order.additional_passengers).map(
    (passenger) => {
      return {
        name: (passenger[0] as Ticket).name,
        phone: (passenger[1] as AdditionalPassenger).telno,
        price: {
          // type: getJapaneseFareType((passenger[0] as Ticket).type),
          type: (passenger[0] as Ticket).type,
          type_id: (passenger[1] as AdditionalPassenger).type,
          telno: (passenger[1] as AdditionalPassenger).telno,
          memo: (passenger[1] as AdditionalPassenger).memo,
          value: (passenger[0] as Ticket).fare
        }
      }
    }
  )
  return {
    id: order.id,
    user_id: order.user_id,
    name: order.ticket_number,
    from: {
      id: 1,
      landmark_type: order.pickup_landmark_type,
      landmark_id: order.pickup_landmark_id,
      name: order.pickup_planned_location_name,
      address: order.pickup_planned_location_address,
      location: {
        lat: order.pickup_planned_location_lat,
        lng: order.pickup_planned_location_lng
      },
      area_id: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.area_id
        : 0,
      layover_landmark_type: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.layover_landmark_type
        : '',
      layover_landmark_id: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.layover_landmark_id
        : '',
      layover_landmark_name: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.layover_landmark_name
        : '',
      layover_landmark_address: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.layover_landmark_address
        : '',
      layover_landmark_location: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.layover_landmark_location
        : { lat: 0, lng: 0 },
      layover_landmark_area_id: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.layover_landmark_area_id
        : 0,
      available: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.available
        : true,
      date_stop_start: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.available === false
          ? moment(order.reffrence_pickup_landmark.date_stop_start).format(
              'YYYY-MM-DD'
            )
          : ''
        : '',
      date_stop_end: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.available === false
          ? moment(order.reffrence_pickup_landmark.date_stop_end).format(
              'YYYY-MM-DD'
            )
          : ''
        : '',
      stop_comment: order.reffrence_pickup_landmark
        ? order.reffrence_pickup_landmark.stop_comment || ''
        : ''
    },
    date_planned_type: order.date_planned_type,
    fromRequestTime:
      order.date_planned_type === 'P'
        ? order.date_planned_start
        : order.date_planned_end,
    fromActualTime: order.date_execution_start,
    to: {
      id: 2,
      landmark_type: order.dropoff_landmark_type,
      landmark_id: order.dropoff_landmark_id,
      name: order.dropoff_planned_location_name,
      address: order.dropoff_planned_location_address,
      location: {
        lat: order.dropoff_planned_location_lat,
        lng: order.dropoff_planned_location_lng
      },
      area_id: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.area_id
        : 0,
      layover_landmark_type: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.layover_landmark_type
        : '',
      layover_landmark_id: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.layover_landmark_id
        : '',
      layover_landmark_name: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.layover_landmark_name
        : '',
      layover_landmark_address: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.layover_landmark_address
        : '',
      layover_landmark_location: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.layover_landmark_location
        : { lat: 0, lng: 0 },
      layover_landmark_area_id: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.layover_landmark_area_id
        : 0,
      available: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.available
        : true,
      date_stop_start: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.available === false
          ? moment(order.reffrence_dropoff_landmark.date_stop_start).format(
              'YYYY-MM-DD'
            )
          : ''
        : '',
      date_stop_end: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.available === false
          ? moment(order.reffrence_dropoff_landmark.date_stop_end).format(
              'YYYY-MM-DD'
            )
          : ''
        : '',
      stop_comment: order.reffrence_dropoff_landmark
        ? order.reffrence_dropoff_landmark.stop_comment || ''
        : ''
    },
    toRequestTime: order.pickup_earliest,
    toActualTime: order.date_execution_end,
    passengers,
    additional_passengers: order.additional_passengers,
    remarks: order.memo,
    coupon_price: order.coupon_price,
    layover_origin_order_id: order.layover_origin_order_id,
    layover_destination_order_id: order.layover_destination_order_id
  }
}

export const getOrder = ($axios: any, orderId: number): Promise<any[]> => {
  return $axios.$get(`/orders/${orderId}`).then((order: Order) => {
    const passengers: any[] = []
    if (
      Object.keys(order.price).length !== 0 &&
      order.price.constructor === Object
    ) {
      order.price.tickets.forEach((fare: any, i: number) => {
        // fare.type = getJapaneseFareType(fare.type)
        passengers.push({
          name: fare.name,
          phone: order.additional_passengers[i].telno,
          price: {
            id: fare.type_id,
            type: fare.type,
            value: fare.fare
          },
          memo: order.additional_passengers[i].memo
        })
      })
    }
    return {
      id: 1,
      user_id: order.user_id,
      name: order.ticket_number,
      from: {
        id: 1,
        landmark_type: order.pickup_landmark_type,
        landmark_id: order.pickup_landmark_id,
        name: order.pickup_planned_location_name,
        address: order.pickup_planned_location_address,
        location: {
          lat: order.pickup_execution_location_lat,
          lng: order.pickup_execution_location_lng
        },
        area_id: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.area_id
          : 0,
        layover_landmark_type: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.layover_landmark_type
          : '',
        layover_landmark_id: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.layover_landmark_id
          : '',
        layover_landmark_name: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.layover_landmark_name
          : '',
        layover_landmark_address: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.layover_landmark_address
          : '',
        layover_landmark_location: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.layover_landmark_location
          : { lat: 0, lng: 0 },
        layover_landmark_area_id: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.layover_landmark_area_id
          : 0,
        available: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.available
          : true,
        date_stop_start: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.available === false
            ? moment(order.reffrence_pickup_landmark.date_stop_start).format(
                'YYYY-MM-DD'
              )
            : ''
          : '',
        date_stop_end: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.available === false
            ? moment(order.reffrence_pickup_landmark.date_stop_end).format(
                'YYYY-MM-DD'
              )
            : ''
          : '',
        stop_comment: order.reffrence_pickup_landmark
          ? order.reffrence_pickup_landmark.stop_comment || ''
          : ''
      },
      date_planned_type: order.date_planned_type,
      fromRequestTime:
        order.date_planned_type === 'P'
          ? order.date_planned_start
          : order.date_planned_end,
      fromActualTime: order.date_execution_start,
      to: {
        id: 2,
        landmark_type: order.dropoff_landmark_type,
        landmark_id: order.dropoff_landmark_id,
        name: order.dropoff_planned_location_name,
        address: order.dropoff_planned_location_address,
        location: {
          lat: order.dropoff_planned_location_lat,
          lng: order.dropoff_execution_location_lng
        },
        area_id: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.area_id
          : 0,
        layover_landmark_type: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.layover_landmark_type
          : '',
        layover_landmark_id: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.layover_landmark_id
          : '',
        layover_landmark_name: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.layover_landmark_name
          : '',
        layover_landmark_address: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.layover_landmark_address
          : '',
        layover_landmark_location: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.layover_landmark_location
          : { lat: 0, lng: 0 },
        layover_landmark_area_id: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.layover_landmark_area_id
          : 0,
        available: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.available
          : true,
        date_stop_start: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.available === false
            ? moment(order.reffrence_dropoff_landmark.date_stop_start).format(
                'YYYY-MM-DD'
              )
            : ''
          : '',
        date_stop_end: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.available === false
            ? moment(order.reffrence_dropoff_landmark.date_stop_end).format(
                'YYYY-MM-DD'
              )
            : ''
          : '',
        stop_comment: order.reffrence_dropoff_landmark
          ? order.reffrence_dropoff_landmark.stop_comment || ''
          : ''
      },
      toRequestTime: '',
      toActualTime: order.date_execution_end,
      passengers,
      remarks: order.memo,
      memo: order.memo,
      coupon_price: order.coupon_price,
      layover_origin_order_id: order.layover_origin_order_id,
      layover_destination_order_id: order.layover_destination_order_id,
      notified: order.notified
    }
  })
}

export const getMyOrders = ($axios: any): Promise<Order[]> => {
  return $axios.$get('/orders/me?limit=10&orderby=created,desc')
}

export const patchOrder = ($axios: any, orderId: number, params: any) => {
  return $axios.$patch(`/orders/${orderId}`, params)
}

export interface PatchOrderParams {
  memo?: string
  additionalPassengers?: AdditionalPassenger[]
  hidden_superior?: boolean
}
export const patchOrderPassengers = (
  $axios: any,
  orderId: number,
  updatedAttr: PatchOrderParams
) => {
  const patch = []
  if (updatedAttr.memo !== undefined) {
    patch.push({
      op: 'replace',
      path: '/memo',
      value: updatedAttr.memo
    })
  }
  if (updatedAttr.additionalPassengers !== undefined) {
    // const capacity_requirements = updatedAttr.additionalPassengers.filter(
    //   (n) => n.ride === true
    // ).length
    patch.push(
      ...[
        {
          op: 'replace',
          path: '/capacity_requirements',
          // value: capacity_requirements
          value: updatedAttr.additionalPassengers.length
        },
        {
          op: 'replace',
          path: '/additional_passengers',
          value: JSON.stringify(updatedAttr.additionalPassengers)
        }
      ]
    )
  }
  if (updatedAttr.hidden_superior !== undefined) {
    patch.push({
      op: 'replace',
      path: '/hidden_superior',
      value: updatedAttr.hidden_superior
    })
  }
  return patch.length === 0
    ? Promise.resolve({ current_status: 'CONFIRMED' })
    : $axios.$patch(`/orders/${orderId}`, patch)
}
export const fixOrder = (
  $axios: any,
  orderId: number,
  updatedAttr: PatchOrderParams
) => {
  const patch = []
  if (updatedAttr.memo !== undefined) {
    patch.push({
      op: 'replace',
      path: '/memo',
      value: updatedAttr.memo
    })
  }
  if (updatedAttr.additionalPassengers !== undefined) {
    patch.push(
      ...[
        {
          op: 'replace',
          path: '/capacity_requirements',
          value: updatedAttr.additionalPassengers.length
        },
        {
          op: 'replace',
          path: '/additional_passengers',
          value: JSON.stringify(updatedAttr.additionalPassengers)
        }
      ]
    )
  }
  return patch.length === 0
    ? Promise.resolve({ current_status: 'CONFIRMED' })
    : $axios.$patch(`/orders/fix/${orderId}`, patch)
}
